export function saveHistory(query) {
    let q = getHistory()
    if (q.includes(query)) {
        return q
    }
    q.push(query)
    localStorage.setItem("queries", JSON.stringify(q))
    return q
}

export function getHistory() {
    let queries = localStorage.getItem("queries");
    if (!queries) {
        queries = "[]"
    }
    let history = JSON.parse(queries);
    console.log("HISTORY: ", history)
    return history
}

export function deleteHistory(query) {
    let q = getHistory()
    let i = q.indexOf(query);
    if (i > -1) {
        q.splice(i, 1);
    }
    localStorage.setItem("queries", JSON.stringify(q))
    return q
}