import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React from "react";

interface Props {
    items: any[]
}

export default function BasicTable({items}: Props) {
    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell width={100}></TableCell>
                        <TableCell align="left">Price + Shipping (-loose, used)</TableCell>
                        <TableCell width={400} align="left">Title</TableCell>
                        <TableCell align="left">PriceCharting</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row: any) => {
                        let price = `$${parseFloat(row.price).toFixed(2)}`;
                        if (row.shipping == "calc") {
                            price += " + calc"
                        } else {
                            price = `$${(parseFloat(row.price) + parseFloat(row.shipping)).toFixed(2)}`
                        }
                        let spreadEl
                        if (row.price_charting_title) {
                            let spread = ((parseFloat(row.price) + (parseFloat(row.shipping) || 0)) - parseFloat(row.price_charting_loose.replace("$", "")))
                            if (spread < 0) {
                                spreadEl = <b style={{color: "green"}}>($-{(spread * -1).toFixed(2)})</b>
                            } else {
                                spreadEl = <b style={{color: "red"}}>(${spread.toFixed(2)})</b>
                            }
                        }

                        return <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                <img style={{width: "95px"}} src={row.image}/>
                            </TableCell>
                            <TableCell align="left">{price} {spreadEl}</TableCell>
                            <TableCell align="left">
                                <a target="_blank" style={{display: "flex"}}
                                   href={row.web_url}>
                                    <span style={{width: "100%"}}>{row.title}</span>
                                    <img style={{width: "80px", height: "40px"}}
                                         src="/ebay.png"/>
                                </a>
                            </TableCell>
                            <TableCell
                                align="left">{row.price_charting_title &&
                                <span>{row.price_charting_title}</span>}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}