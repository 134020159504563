import React, {useEffect, useState} from 'react';
import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import BasicTable from "./Table";
import {Chip, TextField} from "@mui/material";
import {Api, Message} from "../api/api";
import {deleteHistory, getHistory, saveHistory} from "../api/storage";
import {playSound} from "../api/sound";

interface Props {
    api: Api
}

function App({api}: Props) {
    const [query, setQuery] = useState("");
    const [rawQuery, setRawQuery] = useState("")
    const [items, setItems] = useState<Message[]>([]);
    const [lastUpdated, setLastUpdated] = useState("");
    const [history, setHistory] = useState(getHistory());

    let onUpdate = (newItems, lastUpdated) => {
        setItems(prevItems => {
            if ((prevItems.length > 0 && newItems.length > 0) && prevItems[0].title != newItems[0].title) {
                console.log("DING!")
                playSound();
            }
            return newItems
        });
        setLastUpdated(lastUpdated);
    }

    useEffect(() => {
        if (!query) {
            return;
        }

        setHistory(saveHistory(query));

        api.connect(query, (items: Message[], lastUpdated: string) => onUpdate(items, lastUpdated))
        return () => {
            api.disconnect(query);
        }
    }, [query, items])

    return (
        <div className="App">
            <div>
                <h1>WizardPrices.com</h1>
                <i>When you click on links to various merchants on this site and make a purchase, this can result in
                    this site earning a commission. Affiliate programs and affiliations include, but are not limited to,
                    the eBay Partner Network. </i>
                <hr/>
                <br/>
                <button onClick={() => playSound()}>Test Notifications</button>
                <br/>
                <br/>
                <TextField value={rawQuery} id="outlined-basic" label="Search" variant="outlined"
                           onChange={(event) => {
                               setRawQuery(event.target.value);
                           }}
                           onKeyDown={(event) => {
                               if (event.key === 'Enter') {
                                   setQuery(rawQuery)
                               }
                           }}/>
                <br/>
                {history.map((el) => {
                    return <Chip label={el} variant="outlined" onClick={() => setQuery(el)} onDelete={() => {
                        setHistory(deleteHistory(el));
                    }}/>
                })}
                {lastUpdated && <p>Last updated: {new Date(Date.parse(lastUpdated)).toLocaleTimeString()}</p>}
            </div>
            {items && <BasicTable items={items}/>}
        </div>
    );
}


export default App;
