import {ArrayQueue, ConstantBackoff, Websocket, WebsocketBuilder, WebsocketEvent} from "websocket-ts";

export interface Message {
    price: string
    shipping: string
    image: string
    title: string
    price_charting_title: string
}

export class Api {
    private ws;
    private onMessage;
    private query;

    constructor() {
        // let path = "ws://localhost:8080/socket"
        // if (window.location.href.indexOf("wizardprices.com") >= 0) {
        let path = "wss://wizardprices.uw.r.appspot.com/socket"
        // }

        this.query = "";
        this.ws = new WebsocketBuilder(path)
            .withBuffer(new ArrayQueue())           // buffer messages when disconnected
            .withBackoff(new ConstantBackoff(1000)) // retry every 1s
            .build();

        this.ws.addEventListener(WebsocketEvent.open, () => console.log("opened!"));
        this.ws.addEventListener(WebsocketEvent.close, () => console.log("closed!"));
        this.ws.addEventListener(WebsocketEvent.error, (i: Websocket, e) => {
            console.log("on error", e)
        });
        let heartbeat = () => {
            console.log("Sending heartbeat for", this.query);
            this.ws.send(JSON.stringify({query: this.query}))
            setTimeout(heartbeat, 5000);
        }
        setTimeout(heartbeat, 5000);
    }

    public disconnect(query) {
        console.log("Calling disconnect on ", query)
        this.ws.removeEventListener(WebsocketEvent.message, this.onMessage);
    }

    public connect(query: string, onMessage: (items: Message[], lastUpdated: string) => void) {
        this.query = query;
        console.log("Calling connect on ", query)
        this.onMessage = (i: Websocket, ev: MessageEvent) => {
            let items = JSON.parse(ev.data);
            let parsed = JSON.parse(atob(items));
            onMessage(parsed.items, parsed.last_updated);
        };
        this.ws.addEventListener(WebsocketEvent.message, this.onMessage);
        this.ws.send(JSON.stringify({query: query}))

    }

}